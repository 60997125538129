<template>
  <div class="about">
    <h1>About Itgit</h1>
    <h3>My Story</h3>
    <p>
      In 2016 I was diagnosed with Celiac Disease.
      So someone in their 30's now had to give up everything I grew up on.
      And so my journey began. After a few months of trials and errors I discovered I couldn't play
      around with cross
      contamination or even the risk of gluten in my food.
      Along with help of friends and my loving wife, I was able to get healthy and have a pretty
      decent life (I still miss pizza).
      But I'm drastically reminded that the world wasn't setup for people with any issues
      everytime I walk into a store. You have to force companies and resturants to participate
      or even be honest.
      You have to advicated for yourself.
      I've discovered so many small things that make life difficult to trust
      the packages of the products you see on the self.
      <br/>
      It came to me a while ago that I needed something to make it easier for me to find products
      easily and quickly when in a store or when searching for something new.
      And I realzed I could do this for more than myself.
      So here it is, Is there Gluten in this? (Itgit).
      A research tool to help you be confident in your shopping.

      <a href="/join" target="_blank" rel="noopener">How can you help?</a>.
    </p>
  </div>
</template>
